.App {
  text-align: center;
  /* background-color: rgb(72, 255, 48); */
  width: 100vw;
  height: 100vh;
}

.App .logo {
  margin-top: 10px;
  width: 350px;
}

.App .logo2 {
  margin-top: 10px;
  width: 250px;
}

.icon {
  width: 60px;
  margin: 8px;
}

.dt {
  height: 60px;
  margin: 8px;
}

body {
  width: 100vw;
  height: 100vh;
}
